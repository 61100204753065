















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getAreas, getRemoteAreas } from '@/api/areas'
import {
  states,
  types,
  availableForOptions,
  categories, floors
} from '@/utils/property'
import { getTranslations } from '@/utils'
import { createFilter, FilterOperator, FilterType } from '@/utils/filter'

@Component({
  name: 'PropertyCard'
})

export default class extends Vue {
  @Prop({ required: true }) private property: any
  private states = states
  private types = types
  private categories = categories
  private availableForOptions = availableForOptions
  private getTranslations = getTranslations
  private floors = floors
  private areasLoading = false
  private areas = []
  private rules = {
    category: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'change' }
    ],
    available_for: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'change' }
    ],
    type: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'change' }
    ],
    state: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'change' }
    ],
    acreage: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ],
    floor: [
      { required: true, message: this.$t('form.isRequired'), trigger: 'blur' }
    ]
  }

  created() {
    if (this.property.area_id) {
      getAreas(createFilter([{
        type: FilterType.field,
        key: 'id',
        value: this.property.area_id,
        operator: FilterOperator.eq
      }])).then(({ data }) => {
        this.areas = data.collection
      })
    }
  }

  private onCategoryChange() {
    this.$set(this.property, 'type', null)
    this.$set(this.property, 'state', null)
  }

  private async getRemoteAreasList(query: string) {
    this.areasLoading = true
    this.areas = await getRemoteAreas(query, [{
      type: FilterType.field,
      key: 'level',
      value: 1,
      operator: FilterOperator.gt
    }])
    this.areasLoading = false
  }
}
